import Head from "next/head";

import Link from "next/link";
import { MdExtensionOff, MdChevronLeft } from "react-icons/md";

function Error404() {
  const bgLost = `/img/404.jpg`;
  return (
    <div className="bg-cover bg bg-backbg font- poppins w-full bg-no-repeat bg-top ">
      <Head>
        <title>JAMIVIZ - 404 Page</title>

        <meta property="og:title" content="JAMIVIZ - 404 Page" />
      </Head>
      <section id="404">
        <div className="flex flex-col text-center items-center justify-center text-white min-h-screen    ">
          <div className="flex flex-col items-center justify-center sm:mt-20 gap-4  md:my-40 h-full">
            <MdExtensionOff className="  w-8 h-8  md:w-12 md:h-12 " />
            <div className="font-medium text-5xl sm:text-8xl">404</div>
            <div className="font-medium   text-sm lg:text-3xl  ">Oops, Page Not Found!</div>

            <button className="  hover:scale-105 hover:cursor-pointer ">
              <Link href="/" className=" btn btn-primary text-xs   ">
                <MdChevronLeft className="w-5 h-5 pr-1  " />
                Back To Home
              </Link>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Error404;
